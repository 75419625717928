import React from "react";
import TrackVisibility from "react-on-screen";
import Skill from "../Items/Skill";

const skillData = {
  skillContent:
    "With hands-on experience in tools like Figma and Adobe XD, I'm able to bring my designs to life with code that's optimized for both performance and aesthetics. And with my all-in-one development and design expertise, I'm able to deliver high-quality websites that not only look great but also perform well in search engines and drive business results. If you're looking for a versatile, all-in-one solution for your next project, let's connect!",
  progressData: [
    {
      id: 1,
      name: "WordPress",
      percentage: 90,
    },
    {
      id: 2,
      name: "HTML & CSS",
      percentage: 95,
    },
    {
      id: 3,
      name: "React",
      percentage: 90,
    },
    {
      id: 4,
      name: "Angular",
      percentage: 65,
    },
    {
      id: 5,
      name: "SEO",
      percentage: 80,
    },
    {
      id: 6,
      name: "UX/UI Design",
      percentage: 70,
    },
  

  ],
};

function Skills() {
  return (
    <>
      <p className="mb-0">{skillData.skillContent}</p>
      <div className="mt-5">
        <div className="row -mt-50">
          {skillData.progressData.map((progress) => (
            <div className="col-md-6 mt-50" key={progress.id}>
              <TrackVisibility once>
                <Skill progress={progress} />
              </TrackVisibility>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Skills;
