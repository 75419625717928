import React from "react";
import Slider from "react-slick";
import Testimonial from "../Items/Testimonial";

const testimonialsData = [
  {
    id: 1,
    content:
      "He is always there to answer questions and I highly recommend him to anyone looking to grow their business.",
    authorImage: "images/customer-1.png",
    authorName: "Harry Spencer",
    authorStatus: "Client",
  },
  {
    id: 2,
    content:
      "Deneth helped us to improve our website's visibility. He is honest, responsible and hardworking. I would highly recommend him.",
    authorImage: "images/customer-2.png",
    authorName: "Jason Duckette",
    authorStatus: "Client",
  },
  {
    id: 3,
    content:
      "I have been working with Deneth for the last five years and I have found him to be very efficient in his work.",
    authorImage: "images/customer-2.png",
    authorName: "Layton Hasan",
    authorStatus: "Client",
  },
];

function Testimonials() {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
  };

  return (
    <div className="testimonials-wrapper">
      <Slider {...settings} className="padding-slider">
        {testimonialsData.map((testimonial) => (
          <Testimonial testimonial={testimonial} key={testimonial.id} />
        ))}
      </Slider>
    </div>
  );
}

export default Testimonials;
