import React from "react";
import Experience from "../Items/Experience";

const experiencesData = [
  {
    id: 1,
      year: "2022 Mar - Present",
      degree: "Lead Web Developer at Global Print",
      content:
        "Development of websites with tools and technologies like WordPress and React. Handled 130+ client profiles and their website management requirements.",
       },
  {
    id: 2,
    year: "2021 Aug - Present",
    degree: "Web Developer at Asban Inc",
    content:
      "Used technologies like React, WordPress and PHP to meet and fulfil client goals and objectives.", },
  {
    id: 3,

      year: "2022 April - 2022 Jun",
      degree: "Software Developer at IOM World",
      content:
        "Responsible for delivering web applications mainly using Angular and desktop applications using .NET Framework.",
   
  },
  {
    id: 4,
    year: "2020 Sep - 2022 Jun",
    degree: "Lead Developer at Zag Officials",
    content:
      "Developed WordPress websites with functionalities including Search Forms, Custom Fields and Post Types, Website Migrations, Theme Customization, and eCommerce Data analysis.",
  },
  {
    id: 5,
    year: "2021 Apr - 2022 Apr",
    degree: "Project Manager at LBB",
    content:
      "Managed projects in the industries of Content Marketing, SEO, and AI to automate the deployment of blog sites using headless CMS.",
  },
  {
    id: 6,
    year: "2020 Apr - 2022 Apr",
    degree: "Project Manager at WLR",
    content:
      "Managed a team of 20 to output accurate solutions meeting the client requirements in the fields of Web Development and SEO, while working closely with the managers to ensure a smooth experience for the clients.",
  },
];

function Experiences() {
  return (
    <div className="timeline">
      {experiencesData.map((experience) => (
        <Experience experience={experience} key={experience.id} />
      ))}
      <span className="timeline-line"></span>
    </div>
  );
}

export default Experiences;
